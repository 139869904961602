import React from "react";
import LCM from "./LCM";
import Reminders from "./Reminders";
import { getFormSubmissions, sendTestEmail, getAppSettings } from "../data/fetch";
import { getForms } from "../data/fetch";
import { sampleFormsData } from "../data/sample-data";
import { sampleSignupSubmissionsData } from "../data/sample-data";
import functions from "../utils/functions";
import newmanHandLogo from '../images/Logo-1024x200.jpg';
import Login from '../security/Login';
import { appGetUser } from "../security/security";
import LoadingSpinner from "./LoadingSpinner";
import '../App.css';

class SafetyMeetings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectDate: new Date().toISOString().slice(5, 7),
            formData: [],
            companyData: [],
            verifiedCompanyData: [],
            msg: null,
            user: null,
            login: false,
            senderEmail: null,
            tab: "lossControlMeetings",
            loading: true,
            useSampleData: false,
            apiKey: null,
        };
    }

    componentDidMount = async() => {
        this.login();
    };

    login = async() => {
        const user = await appGetUser();
        if (user) {
            // console.log(user);
            this.setState({ user, login: true });
            this.fetchData();
        } else {
            this.setState({ loading: false });
        }
    };

    refresh = () => {
        this.setState({ 
            projectDate: new Date().toISOString().slice(5, 7),
            formData: [],
            companyData: [],
            verifiedCompanyData: [],
            msg: null,
            user: null,
            login: false,
            senderEmail: null,
            tab: "lossControlMeetings",
            loading: true,
            apiKey: null,
        }, () => {
            this.login();
        });
    }

    fetchData = async() => {
        this.setState({ loading: true });
        let formData;
        let companyData;
        let verifiedCompanyData;
        if (this.state.useSampleData) {
            this.setState({ senderEmail: this.state.user.email });
            formData = this.onGetSampleForms();
            // console.log("fetchData formData", formData);
            companyData = this.onGetSampleCompanyData(formData);
            verifiedCompanyData = this.onVerifyData(formData, companyData);
        } else {
            const appSettings = await getAppSettings(this.state.user.sessionId);
            this.setState({ senderEmail: appSettings.sender, apiKey: appSettings.key });
            const apiKey = appSettings.key;
            formData = await this.onGetForms(apiKey);
            // console.log("formData", formData);
            companyData = await this.onGetCompanyData(formData, apiKey);
            // console.log("companyData", companyData);
            verifiedCompanyData = this.onVerifyData(formData, companyData);
            // console.log("verifiedCompanyData", verifiedCompanyData);
        }
        this.setState({ formData, companyData, verifiedCompanyData, loading: false });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.onVerifyData(this.state.formData, this.state.companyData);
        });
    };

    onGetCompanyData = async (formData, apiKey) => {
        const formKey = functions.getFormKey(formData, "0000");
        // console.log("formKey", formKey);
        if (formKey.error) {
            this.setMsg(formKey.error, "error");
            return null;
        } else {
            const response = await getFormSubmissions(formKey, apiKey);
            // console.log("response", response);
            const dataBody = response.content;
            // console.log("dataBody", dataBody);
            const companyData = functions.getCompanyData(dataBody);
            return companyData;
        }
    };

    onGetSampleCompanyData = (formData) => {
        const formKey = functions.getFormKey(formData, "0000");
        // console.log("formKey", formKey);
        if (formKey.error) {
            this.setMsg(formKey.error, "error");
        } else {
            const dataBody = sampleSignupSubmissionsData.content;
            const companyData = functions.getCompanyData(dataBody);
            return companyData;
        }
    };

    onGetForms = async (apiKey) => {
        const formResp = await getForms(apiKey);
        const formArr = formResp.content;
        // console.log("formArr", formArr);
        const formData = functions.getFormsObj(formArr);
        return formData;
    };
    
    onGetSampleForms = () => {
        const formArr = sampleFormsData.content;
        // console.log("formArr", formArr);
        const formData = functions.getFormsObj(formArr);
        return formData;
    };

    onVerifyData = (formData, companyData) => {
        // console.log("companyData", companyData);
        const { projectDate } = this.state;
        if (companyData.length > 0) {
            const verifiedCompanyData = functions.verifyData(formData, companyData, projectDate);
            return verifiedCompanyData;
        } else {
            this.setMsg("No company data found that matches criteria. Check your forms.", "error");
        }
    };

    onSendTest = async (emailType) => {
        const { user } = this.state;
        this.setMsg("Sending Test...", "message");
        const response = await sendTestEmail(user.sessionId, user.email, "Test Email", emailType);
        this.setMsg(response.msg, response.msgType);
    };

    setMsg = (msg, msgType) => {
        this.setState({ msg, msgType });
    };

    setUser = (value) => {
        this.setState({ user: value });
    };

    setLogin = (value) => {
        // console.log('setLogin', value);
        this.setState({ login: value });
    };

    onTabClick = (e) => {
        this.setState({ tab: e.target.name });
    };

    render() {
        // console.log("verifiedCompanyData", this.state.verifiedCompanyData);
        const { user, login, apiKey, senderEmail, projectDate, msgType, msg, tab, verifiedCompanyData, formData, loading } = this.state;
        // console.log("user", user);
        // console.log("login", login);
        return (
            <div>
                <div className="loss-control-header">
                    <img src={newmanHandLogo} className="newman-hand-logo" alt='Newman Hand Logo' />
                    <h1 className="app-name">Loss Control</h1>
                    <div className="btns-div">
                        <div className="loss-control-btns-container">
                            {user ? 
                                <>
                                    <h4 className="loss-control-btn" onClick={this.refresh} >Refresh</h4>
                                    <h4 className="loss-control-btn" onClick={() => this.setUser(null)}>Logoff {user.fname}</h4> &nbsp; &nbsp;
                                </>
                                :   
                                    <h4 className="loss-control-btn" onClick={() => this.setLogin(false)}>Signup/Login</h4>
                            }
                        </div>
                    </div>
                </div>
                <div className={msgType === "message" ? "loss-control-msg-container message" : "loss-control-msg-container error"}>
                    {msg}
                </div>
                <div className="pages">
                    {loading ? <LoadingSpinner className="pages-loading-spinner"/> :
                        <>
                            {login ? 
                                <>
                                    {user ? 
                                        <>
                                            <div className="tabs">
                                                <button className={tab === "lossControlMeetings" ? "tab-btn active-tab" : "tab-btn"} name="lossControlMeetings" onClick={(e) => this.onTabClick(e)}>Loss Control Meetings</button>
                                                <button className={tab === "reminders" ? "tab-btn active-tab" : "tab-btn"} name="reminders" onClick={(e) => this.onTabClick(e)}>Reminders</button>
                                            </div>
                                            {tab === "lossControlMeetings" ?
                                                <LCM 
                                                    data={verifiedCompanyData} 
                                                    setMsg={this.setMsg} 
                                                    user={user} 
                                                    senderEmail={senderEmail} 
                                                    onSendTest={this.onSendTest} 
                                                    projectDate={projectDate} 
                                                    handleChange={this.handleChange} 
                                                />
                                                :
                                                <Reminders 
                                                    verifiedCompanyData={verifiedCompanyData} 
                                                    formData={formData} 
                                                    setMsg={this.setMsg} 
                                                    user={user} 
                                                    senderEmail={senderEmail} 
                                                    onSendTest={this.onSendTest} 
                                                    projectDate={projectDate} 
                                                    handleChange={this.handleChange}
                                                    apiKey={apiKey}
                                                />
                                            }
                                        </>
                                        :
                                        <div className="login-required-msg">Please Log In to Use Application</div>
                                    }
                                </> :
                                <div className="login-page">
                                    <h2>Login Page</h2>
                                    <h4>Please Log In to Access App</h4>
                                    <Login setUser={this.setUser} cancel={this.setLogin} login={this.login} />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        );
    }
}


export default SafetyMeetings;