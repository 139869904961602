const functions = {
    getFormsObj: (formArr) => {
        // console.log("formArr", formArr);
        let forms = {};
        let duplicateIDs = {};
        for (let i = 0; i < formArr.length; i++) {
            if (formArr[i].status === "ENABLED") {
                const title = formArr[i].title.slice(0, 4);
                if (forms[title]) {
                    duplicateIDs[title] = "duplicate";
                } else {
                    forms[title] = formArr[i];
                }
            }
        }
        return { forms, duplicateIDs };
    },

    getFormKey: (formData, formID) => {
        if (formData.duplicateIDs[formID]) {
            return { error: `Multiple forms have titles beginning with '${formID}'.` };
        } else if (formData.forms[formID]) {
            return formData.forms[formID].id;
        } else {
            return { error: `No form beginning with '${formID}' was found.` };
        }
    },

    getFormKeysFromList: (formData, verifiedCompanyData) => {
        // console.log(verifiedCompanyData);
        let formKeysObj = {};
        let formKeysArr = [];
        for (let i = 0; i < verifiedCompanyData.length; i++) {
            const id = verifiedCompanyData[i].formID;
            if (!formKeysObj[id]) {
                const key = functions.getFormKey(formData, id);
                formKeysObj[id] = key;
                if (!key.error) {
                    formKeysArr.push({ "formID": id, "formKey": key });
                }
            }
        }
        return formKeysArr;
    },

    getCompanyData: (dataBody) => {
        // console.log("dataBody", dataBody);
        let dataBodyObj = {};
        let companyData = [];
        for (let i = 0; i < dataBody.length; i++) {
            if (dataBody[i].status === "ACTIVE") {
                const object = dataBody[i].answers;
                for (const data in object) {
                    // console.log("data", object[data]);
                    dataBodyObj[object[data].name] = object[data].answer;
                }
                // console.log("dataBodyObj", dataBodyObj);             
                if (dataBodyObj.companyName && dataBodyObj.email && dataBodyObj.industry) {
                    companyData.push({
                        "id": dataBody[i].id,
                        "company": dataBodyObj.companyName,
                        "email": dataBodyObj.email,
                        "industry": dataBodyObj.industry,
                        "formID": null,
                        "formStatus": null
                    });
                }
            }
        }
        return companyData;
    },

    verifyData: (formData, companyData, projectDate) => {
        // console.log("formData", formData);
        const forms = formData.forms;
        const duplicateIDs = formData.duplicateIDs;
        for (let i = 0; i < companyData.length; i++) {
            if (companyData[i].industry) {
                const formID = `${companyData[i].industry.slice(0, 2)}${projectDate}`;
                companyData[i]["formID"] = formID;
                if (forms[formID]) {
                    if (duplicateIDs[formID]) {
                        companyData[i]["formStatus"] = `More Than One Form Has ${formID} ID`;
                    } else {
                        companyData[i]["formStatus"] = "Ready to Use";
                    }
                    companyData[i]["formUrl"] = forms[formID].url;
                } else {
                    if (duplicateIDs[formID]) {
                        companyData[i]["formStatus"] = `More Than One Form Has ${formID} ID`;
                    } else {
                        companyData[i]["formStatus"] = "Form Not Found";
                    }
                }
            }
        }
        return companyData;
    },

    verifySubmissions: (formSubmissions, verifiedCompanyData) => {
        // console.log("formSubmissions", formSubmissions);
        // console.log("verifiedCompanyData", verifiedCompanyData);
        const year = new Date().toISOString().slice(0, 4);
        let formSubsObj = {};
        for (let i = 0; i < formSubmissions.length; i++) {
            formSubsObj[formSubmissions[i].formID] = { "contentArr": formSubmissions[i].submissions.content };
        }
        // console.log("formSubsObj", formSubsObj);
        for (let i = 0; i < formSubmissions.length; i++) {
            const obj = formSubsObj[formSubmissions[i].formID];
            obj["contentObj"] = {};
            if (obj.contentArr.length > 0) {
                obj.contentArr.forEach(object => {
                    if (object.status === "ACTIVE") {
                        const custId = functions.getSubCustId(object.answers);
                        // console.log("custId", custId);
                        obj["contentObj"][custId] = { "form_id": object.form_id, "created_at": object.created_at };
                    }
                });
            }
        }
        if (Object.keys(formSubsObj).length > 0) {
            for (let i = 0; i < verifiedCompanyData.length; i++) {
                // console.log("formSubsObj", formSubsObj);
                // console.log(`verifiedCompanyData[${i}]`, verifiedCompanyData[i]);
                if (formSubsObj[verifiedCompanyData[i].formID]) {
                    if (Object.keys(formSubsObj[verifiedCompanyData[i].formID].contentObj).length > 0 && formSubsObj[verifiedCompanyData[i].formID].contentObj[verifiedCompanyData[i].id]) {
                        // console.log(formSubsObj[verifiedCompanyData[i].formID].contentObj.custId);
                        if (formSubsObj[verifiedCompanyData[i].formID].contentObj[verifiedCompanyData[i].id].created_at.slice(0, 4) === year) {
                            verifiedCompanyData[i]["responseStatus"] = "Response Received";
                        } else {
                            verifiedCompanyData[i]["responseStatus"] = "No Response";
                        }
                    } else {
                        verifiedCompanyData[i]["responseStatus"] = "No Response";
                    }
                } else {
                    verifiedCompanyData[i]["responseStatus"] = "No Response";
                }
            }
            return verifiedCompanyData;
        }
    },

    getSubCustId: (answers) => {
        const answersArr = Object.values(answers);
        let custId;
        answersArr.forEach(answer => {
            if (answer.name === "Custid") {
                custId = answer.answer;
            }
        });
        return custId;
    },

    getEmailList: (companyData) => {
        let emailList = [];
        let message = "Sending Emails";
        let messageType = "message";
        if (companyData.length > 0) {
            for (let i = 0; i < companyData.length; i++) {
                if (companyData[i].formStatus === "Ready to Use") {
                    emailList.push({ "email": companyData[i].email, "formUrl": `${companyData[i].formUrl}?session=${companyData[i].id}&Custid=${companyData[i].id}` });
                    // use line above for prod, line below for testing
                    // emailList.push({ "email": `hopfenate+${i}@gmail.com`, "formUrl": `${companyData[i].formUrl}?session=Custid&Custid=${companyData[i].id}` });
                } else {
                    message = "All forms must be 'Ready to Use' before emails can be sent.";
                    messageType = "error";
                }
            }
            if (messageType === "message") {
                return { "emailList": emailList, "msg": message, "msgType": messageType };
            }
            return { "msg": message, "msgType": messageType };
        }
        return { "msg": "No Recipients Selected", "msgType": "error" };
    },
};

export default functions;