// To re-authenticate, request a security code to be sent to your email.
import './login.css';
import { useState, useEffect } from 'react';
import { appGetEmail, appRequestSecurityCode, appConfirmSecurityCode } from './security';

function Login(props) {

    // const [input, setInput] = useState('');
    const [userType, setUserType] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [msg, setMsg] = useState('');
    const [waitingForSecurityCode, setWaitingForSecurityCode] = useState(false);

    // One time process
    useEffect(() => {
        // Get email from local storage (Cookie)
        setEmail(appGetEmail());
    }, []);

    async function requestSecurityCode() {
        setMsg(null);
        setWaitingForSecurityCode(false);
        // validate input first
        const check = await appRequestSecurityCode({ userType, fname, lname, email });
        // set error message
        setMsg(check);
        // If no errors setup the security code input
        if (!check) {
            setWaitingForSecurityCode(true);
        } else {
            setWaitingForSecurityCode(false);
        }
    }

    async function validateSecurityCode() {
        setMsg(null)
        const resp = await appConfirmSecurityCode(email, securityCode);
        if (resp.status === 0) {
            props.cancel(true);
            props.setUser(resp.user);
            props.login();
        } else {
            setWaitingForSecurityCode(false);
            setMsg(resp.msg);
        }
    }


    return (
        <div className='clNiceBox'>
            {msg ? <div className='clMsg'>{msg}</div> : null}
            {waitingForSecurityCode ? <div className='clInfoMsg'>Check your email for the Security Code!</div> : null}

            {!userType ?
                <>
                    <button className='clButton' onClick={() => setUserType('newUser')}>New User</button>
                    <button className='clButton' onClick={() => setUserType('existingUser')}>Existing User</button>
                </>
                : null
            }

            {userType === 'newUser' ?
                <>
                    <label>First Name
                        <input type='text' className='clInput' value={fname} onInput={e => setFname(e.target.value)} />
                    </label>
                    <label>Last Name
                        <input type='text' className='clInput' value={lname} onInput={e => setLname(e.target.value)} />
                    </label>
                </>
                : null
            }

            {userType ?
                <>
                    <label>email
                        <input type='text' className='clInput' placeholder='you@example.com' value={email} onInput={e => setEmail(e.target.value)} />
                    </label>
                    <button className='clButton' onClick={() => requestSecurityCode() }>
                        Request Security Code
                    </button>
                </>
                : null
            }
            {waitingForSecurityCode ?
                <>
                    <br />
                    <label>Security Code
                    <br />
                        <input type='text' className='clInput clSecurityCode' value={securityCode} onInput={e => setSecurityCode(e.target.value)} />
                    </label>
                    <br />
                    <button className='clButton' onClick={() => validateSecurityCode()}>
                        Validate Code
                    </button>
                </>
                : null
            }

            <button className='clButton' onClick={() => props.cancel(true)}>
                Cancel
            </button>
        </div>
    );
}

export default Login;