import './App.css';
import SafetyMeetings from './components/SafetyMeetings';
import background from './images/tree.jpg';
import Version from './version/Version.jsx';

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
        <SafetyMeetings />
        <Version />
    </div>
  );
}

export default App;
