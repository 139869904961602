import React from 'react';
import ReminderTable from './tables/ReminderTable';
import { getFormSubmissions, sendEmails } from "../data/fetch";
// import { sampleFormsSubmissionsData } from '../data/sample-data';
import functions from "../utils/functions";
import MonthDropdown from "./MonthDropdown";
import '../App.css';
import LoadingSpinner from './LoadingSpinner';

class Reminders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRecipients: [],
            formSubmissions: [],
            projectDate: this.props.projectDate,
            verifiedCompDatWithSubs: [],
            loading: true,
        };
    }

    componentDidMount = () => {
        this.fetchReminderData();
    };

    componentDidUpdate = () => {
        if (this.props.projectDate !== this.state.projectDate) {
            this.setState({ projectDate: this.props.projectDate, loading: true }, () => {
                this.fetchReminderData();
            });
        }
    };

    onSend = async () => {
        const { setMsg, user } = this.props;
        let response = functions.getEmailList(this.state.selectedRecipients);
        const info = { "sessionId": user.sessionId, "emailType": "reminder", "emailList": response.emailList };
        setMsg(response.msg, response.msgType);
        if (response.emailList) {
            response = await sendEmails(info);
            setMsg(response.msg, response.msgType);
            if (response.msgType === 'message') {
                this.setState({ selectedRecipients: [] });
            }
        }
    };

    fetchReminderData = async () => {
        const { formData, verifiedCompanyData } = this.props;
        const formSubmissions = await this.onGetFormSubmissions(formData, verifiedCompanyData);
        // console.log("formSubmissions", formSubmissions);
        // console.log("verifiedCompanyData", verifiedCompanyData);
        const verifiedCompDatWithSubs = functions.verifySubmissions(formSubmissions, verifiedCompanyData);
        this.setState({ verifiedCompDatWithSubs });
        this.setState({ loading: false });
    };

    onSelectedRowsChange = (selected) => {
        this.setState({ selectedRecipients: selected.selectedRows });
    };

    onGetFormSubmissions = async (formData, verifiedCompanyData) => {
        const formKeys = functions.getFormKeysFromList(formData, verifiedCompanyData);
        // console.log("formKeys", formKeys);
        // const appSettings = await getAppSettings(this.props.user.sessionId);
        // const apiKey = appSettings.key;
        const apiKey = this.props.apiKey;
        // console.log(apiKey);
        let formSubmissions = [];
        for (let i = 0; i < formKeys.length; i++) {
            const formId = formKeys[i].formKey;
            const subs = await getFormSubmissions(formId, apiKey);
            formSubmissions.push({ "formID": formKeys[i].formID, "submissions": subs });
        }
        // console.log(formSubmissions);
        return formSubmissions;
    };

    render() {
        return (
            <div className="lcm-page">
                {this.state.loading ? 
                    <LoadingSpinner className="pages-loading-spinner"/>
                    :
                    <>
                        <div className="lcm-header">
                            <div className="lcm-header-left">
                                <h2>Reminders</h2>
                            </div>
                            <div className="lcm-header-right">
                                <h3 className="lcm-admin-email">Sender Email: {this.props.senderEmail}</h3>
                                <MonthDropdown projectDate={this.state.projectDate} handleChange={this.props.handleChange} />                        
                                <div className="lcm-btns-container">
                                    <h4 className="loss-control-btn" onClick={() => this.props.onSendTest("reminder")}>Send Test</h4>
                                    <h4 className="loss-control-btn" onClick={this.onSend}>Send Reminders</h4>
                                </div>
                            </div>
                        </div>
                        <ReminderTable data={this.state.verifiedCompDatWithSubs} onSelectedRowsChange={this.onSelectedRowsChange} />
                    </>
                }
            </div>
        )
    }
}

export default Reminders;