export const sampleUserSession = {
    "sessionId": "35a82b1f-978f-44b6-8172-42e06a59c84b",
    "lname": "Hopfe",
    "email": "nathan@leanminds.ca",
    "fname": "Nathan"
};

export const sampleFormsData = {
    "responseCode": 200,
    "message": "success",
    "content": [
        {
            "id": "212624981027051",
            "username": "hopfenate",
            "title": "Name Test Form",
            "height": "539",
            "status": "ENABLED",
            "created_at": "2021-09-20 12:44:24",
            "updated_at": "2021-09-20 14:11:33",
            "last_submission": "2021-09-20 12:49:40",
            "new": "3",
            "count": "3",
            "type": "LEGACY",
            "url": "https://form.jotform.com/212624981027051"
        },
        {
            "id": "212576363743258",
            "username": "hopfenate",
            "title": "0000 - Nathan's Sign-Up Form",
            "height": "0",
            "status": "ENABLED",
            "created_at": "2021-09-15 17:06:19",
            "updated_at": "2021-09-20 15:18:31",
            "last_submission": "2021-09-20 15:40:16",
            "new": "1",
            "count": "6",
            "type": "LEGACY",
            "url": "https://form.jotform.com/212576363743258"
        },
        {
            "id": "212576363743259",
            "username": "hopfenate",
            "title": "0209 Farming",
            "status": "ENABLED",
            "url": "https://form.jotform.com/212576363743259"
        },
        {
            "id": "212576363743260",
            "username": "hopfenate",
            "title": "0109 Construction",
            "status": "ENABLED",
            "url": "https://form.jotform.com/212576363743260"
        },
        {
            "id": "212576363743261",
            "username": "hopfenate",
            "title": "0309 Manufacturing",
            "status": "ENABLED",
            "url": "https://form.jotform.com/212576363743261"
        },
        {
            "id": "212576363743262",
            "username": "hopfenate",
            "title": "0210 Farming",
            "status": "ENABLED",
            "url": "https://form.jotform.com/212576363743262"
        },
        {
            "id": "212576363743263",
            "username": "hopfenate",
            "title": "0110 Construction",
            "status": "ENABLED",
            "url": "https://form.jotform.com/212576363743263"
        },
        // {
        //     "id": "212576363743264",
        //     "username": "hopfenate",
        //     "title": "Manufacturing-10",
        //     "status": "ENABLED",
        //     "url": "https://form.jotform.com/212576363743264"
        // },
    ],
    "duration": "27.33ms",
    "resultSet": {
        "offset": 0,
        "limit": 20,
        "count": 2
    },
    "limit-left": 987
};

export const sampleSignupSubmissionsData = {
    "responseCode": 200,
    "message": "success",
    "content": [
        {
            "id": "5079760168322043986",
            "form_id": "212576363743258",
            "ip": "207.148.176.238",
            "created_at": "2021-09-20 15:40:16",
            "status": "ACTIVE",
            "new": "0",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-22 14:18:25",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname",
                    "answer": {
                        "first": "pdfFname",
                        "last": "pdfLname"
                    },
                    "prettyFormat": "pdfFname pdfLname"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email",
                    "answer": "hopfenate+test@gmail.com"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone"
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio",
                    "answer": "01 - Construction"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox",
                    "answer": "PDF Construction"
                }
            }
        },
        {
            "id": "5079755691839424528",
            "form_id": "212576363743258",
            "ip": "184.64.33.81",
            "created_at": "2021-09-20 15:32:49",
            "status": "ACTIVE",
            "new": "1",
            "flag": "0",
            "notes": "",
            "updated_at": null,
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname",
                    "answer": {
                        "first": "Larry",
                        "last": "Shumlich"
                    },
                    "prettyFormat": "Larry Shumlich"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email",
                    "answer": "hopfenate+larry@gmail.com"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone",
                    "answer": {
                        "area": "403",
                        "phone": "6819586"
                    },
                    "prettyFormat": "(403) 6819586"
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature",
                    "answer": "https://www.jotform.com/uploads/hopfenate/212576363743258/5079755691839424528/5079755691839424528_signature_14.png"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio",
                    "answer": "02 - Farming"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox",
                    "answer": "Lean Minds"
                }
            }
        },
        {
            "id": "5079732048324513308",
            "form_id": "212576363743258",
            "ip": "207.148.176.238",
            "created_at": "2021-09-20 14:53:24",
            "status": "DELETED",
            "new": "1",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-20 14:55:49",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname",
                    "answer": {
                        "first": "PostmanFName",
                        "last": "PostmanLName"
                    },
                    "prettyFormat": "PostmanFName PostmanLName"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email",
                    "answer": "hopfenate+postman@gmail.com"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone"
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio",
                    "answer": "02 - Farming"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox",
                    "answer": "PostmanCompany"
                }
            }
        },
        {
            "id": "5079728148321709721",
            "form_id": "212576363743258",
            "ip": "207.148.176.238",
            "created_at": "2021-09-20 14:46:54",
            "status": "ACTIVE",
            "new": "0",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-22 14:18:52",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname",
                    "answer": {
                        "first": "PostmanFName",
                        "last": "PostmanLName"
                    },
                    "prettyFormat": "PostmanFName PostmanLName"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email",
                    "answer": "hopfenate+postman@gmail.com"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone"
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio",
                    "answer": "02 - Farming"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox",
                    "answer": "PostmanFarms"
                }
            }
        },
        {
            "id": "5079726418328811180",
            "form_id": "212576363743258",
            "ip": "207.148.176.238",
            "created_at": "2021-09-20 14:44:01",
            "status": "ACTIVE",
            "new": "0",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-20 15:14:58",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone"
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox"
                },
                "0": {
                    "answer": "PostmanFName",
                    "prettyFormat": "Farming"
                }
            }
        },
        {
            "id": "5079724328326270733",
            "form_id": "212576363743258",
            "ip": "207.148.176.238",
            "created_at": "2021-09-20 14:40:32",
            "status": "DELETED",
            "new": "1",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-20 14:44:17",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone"
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox"
                },
                "0": {
                    "answer": "PostmanFName",
                    "prettyFormat": "Farming"
                }
            }
        },
        {
            "id": "5076397136673165973",
            "form_id": "212576363743258",
            "ip": "207.148.176.6",
            "created_at": "2021-09-16 18:15:13",
            "status": "ACTIVE",
            "new": "0",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-22 14:18:33",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname",
                    "answer": {
                        "first": "Nate2",
                        "last": "Hopfe2"
                    },
                    "prettyFormat": "Nate2 Hopfe2"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email",
                    "answer": "hopfenate+nate2@gmail.com"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone",
                    "answer": "() "
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature",
                    "answer": "https://www.jotform.com/uploads/hopfenate/212576363743258/5076397136673165973/5076397136673165973_signature_14.png"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio",
                    "answer": "01 - Construction"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox",
                    "answer": "Nate Construction"
                }
            }
        },
        {
            "id": "5076364776676759225",
            "form_id": "212576363743258",
            "ip": "207.148.176.6",
            "created_at": "2021-09-16 17:21:17",
            "status": "ACTIVE",
            "new": "0",
            "flag": "0",
            "notes": "",
            "updated_at": "2021-09-22 14:18:45",
            "answers": {
                "7": {
                    "name": "7",
                    "order": "8",
                    "text": "Submit Form",
                    "type": "control_button"
                },
                "10": {
                    "name": "name",
                    "order": "2",
                    "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                    "text": "Name",
                    "type": "control_fullname",
                    "answer": {
                        "first": "Nate",
                        "last": "Hopfe"
                    },
                    "prettyFormat": "Nate Hopfe"
                },
                "11": {
                    "name": "email",
                    "order": "4",
                    "text": "E-mail",
                    "type": "control_email",
                    "answer": "nathan@leanminds.ca"
                },
                "12": {
                    "name": "phoneNumber12",
                    "order": "5",
                    "sublabels": "{\"area\":\"Area Code\",\"phone\":\"Phone Number\"}",
                    "text": "Phone Number",
                    "type": "control_phone",
                    "answer": "() "
                },
                "13": {
                    "name": "signupForm",
                    "order": "1",
                    "text": "Sign-Up Form",
                    "type": "control_head"
                },
                "14": {
                    "name": "signature",
                    "order": "7",
                    "selectedField": "528c8b8186659c5616000004",
                    "text": "Signature",
                    "type": "control_signature",
                    "answer": "https://www.jotform.com/uploads/hopfenate/212576363743258/5076364776676759225/5076364776676759225_signature_14.png"
                },
                "15": {
                    "name": "industry",
                    "order": "6",
                    "text": "Industry",
                    "type": "control_radio",
                    "answer": "03 - Manufacturing"
                },
                "17": {
                    "name": "companyName",
                    "order": "3",
                    "text": "Company Name",
                    "type": "control_textbox",
                    "answer": "Nate Manufacturing"
                },
                "6": {
                    "answer": "Manufacturing Insurance"
                }
            }
        }
    ],
    "duration": "37.25ms",
    "resultSet": {
        "offset": 0,
        "limit": 20,
        "count": 8
    },
    "limit-left": 988
};

export const sampleFormsSubmissionsData = [
    {
        "formID": "0409",
        "submissions": {
            "responseCode": 200,
            "message": "success",
            "content": [],
            "duration": "12.03ms",
            "resultSet": {
                "offset": 0,
                "limit": 20,
                "count": 0
            },
            "limit-left": 935
        }
    },
    {
        "formID": "0109",
        "submissions": {
            "responseCode": 200,
            "message": "success",
            "content": [
                {
                    "id": "5079713438323911346",
                    "form_id": "212624981027051",
                    "ip": "207.148.176.238",
                    "created_at": "2021-09-20 14:22:23",
                    "status": "DELETED",
                    "new": "1",
                    "flag": "0",
                    "notes": "",
                    "updated_at": "2021-09-24 16:51:21",
                    "answers": {
                        "1": {
                            "name": "0109",
                            "order": "1",
                            "text": "0109 - Construction",
                            "type": "control_head"
                        },
                        "2": {
                            "name": "submit2",
                            "order": "5",
                            "text": "Submit",
                            "type": "control_button"
                        },
                        "4": {
                            "answer": "firstNameFromTestFile"
                        },
                        "6": {
                            "name": "safetyMeeting",
                            "order": "2",
                            "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                            "text": "Safety Meeting Supervisor",
                            "type": "control_fullname"
                        },
                        "7": {
                            "name": "signature",
                            "order": "4",
                            "selectedField": "528c8b8186659c5616000004",
                            "text": "Signature",
                            "type": "control_signature"
                        },
                        "8": {
                            "maxValue": "",
                            "name": "numberOf",
                            "order": "3",
                            "text": "Number of Employees at Safety Meeting",
                            "type": "control_number"
                        }
                    }
                },
                {
                    "id": "5079659058324573940",
                    "form_id": "212624981027051",
                    "ip": "207.148.176.238",
                    "created_at": "2021-09-20 12:51:45",
                    "status": "DELETED",
                    "new": "1",
                    "flag": "0",
                    "notes": "",
                    "updated_at": "2021-09-24 16:51:21",
                    "answers": {
                        "1": {
                            "name": "0109",
                            "order": "1",
                            "text": "0109 - Construction",
                            "type": "control_head",
                            "answer": "APITestName"
                        },
                        "2": {
                            "name": "submit2",
                            "order": "5",
                            "text": "Submit",
                            "type": "control_button"
                        },
                        "6": {
                            "name": "safetyMeeting",
                            "order": "2",
                            "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                            "text": "Safety Meeting Supervisor",
                            "type": "control_fullname"
                        },
                        "7": {
                            "name": "signature",
                            "order": "4",
                            "selectedField": "528c8b8186659c5616000004",
                            "text": "Signature",
                            "type": "control_signature"
                        },
                        "8": {
                            "maxValue": "",
                            "name": "numberOf",
                            "order": "3",
                            "text": "Number of Employees at Safety Meeting",
                            "type": "control_number"
                        }
                    }
                },
                {
                    "id": "5079657808321796916",
                    "form_id": "212624981027051",
                    "ip": "207.148.176.238",
                    "created_at": "2021-09-20 12:49:40",
                    "status": "DELETED",
                    "new": "1",
                    "flag": "0",
                    "notes": "",
                    "updated_at": "2021-09-24 16:51:21",
                    "answers": {
                        "1": {
                            "name": "0109",
                            "order": "1",
                            "text": "0109 - Construction",
                            "type": "control_head"
                        },
                        "2": {
                            "name": "submit2",
                            "order": "5",
                            "text": "Submit",
                            "type": "control_button"
                        },
                        "4": {
                            "answer": "TestName"
                        },
                        "6": {
                            "name": "safetyMeeting",
                            "order": "2",
                            "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                            "text": "Safety Meeting Supervisor",
                            "type": "control_fullname"
                        },
                        "7": {
                            "name": "signature",
                            "order": "4",
                            "selectedField": "528c8b8186659c5616000004",
                            "text": "Signature",
                            "type": "control_signature"
                        },
                        "8": {
                            "maxValue": "",
                            "name": "numberOf",
                            "order": "3",
                            "text": "Number of Employees at Safety Meeting",
                            "type": "control_number"
                        }
                    }
                },
                {
                    "id": "5079655438322646653",
                    "form_id": "212624981027051",
                    "ip": "207.148.176.238",
                    "created_at": "2021-09-20 12:45:43",
                    "status": "DELETED",
                    "new": "1",
                    "flag": "0",
                    "notes": "",
                    "updated_at": "2021-09-20 12:49:59",
                    "answers": {
                        "1": {
                            "name": "0109",
                            "order": "1",
                            "text": "0109 - Construction",
                            "type": "control_head"
                        },
                        "2": {
                            "name": "submit2",
                            "order": "5",
                            "text": "Submit",
                            "type": "control_button"
                        },
                        "3": {
                            "answer": {
                                "first": "TestFName",
                                "last": "TestLName"
                            },
                            "prettyFormat": "TestFName TestLName"
                        },
                        "6": {
                            "name": "safetyMeeting",
                            "order": "2",
                            "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                            "text": "Safety Meeting Supervisor",
                            "type": "control_fullname"
                        },
                        "7": {
                            "name": "signature",
                            "order": "4",
                            "selectedField": "528c8b8186659c5616000004",
                            "text": "Signature",
                            "type": "control_signature"
                        },
                        "8": {
                            "maxValue": "",
                            "name": "numberOf",
                            "order": "3",
                            "text": "Number of Employees at Safety Meeting",
                            "type": "control_number"
                        }
                    }
                }
            ],
            "duration": "21.95ms",
            "resultSet": {
                "offset": 0,
                "limit": 20,
                "count": 4
            },
            "limit-left": 934
        }
    },
    {
        "formID": "0209",
        "submissions": {
            "responseCode": 200,
            "message": "success",
            "content": [
                {
                    "id": "5102300875617044345",
                    "form_id": "212717244375052",
                    "ip": "207.148.176.165",
                    "created_at": "2021-10-16 17:48:07",
                    "status": "ACTIVE",
                    "new": "1",
                    "flag": "0",
                    "notes": "",
                    "updated_at": null,
                    "answers": {
                        "1": {
                            "name": "0209",
                            "order": "1",
                            "text": "0209 - Farming",
                            "type": "control_head"
                        },
                        "2": {
                            "name": "submit2",
                            "order": "5",
                            "text": "Submit",
                            "type": "control_button"
                        },
                        "6": {
                            "name": "safetyMeeting",
                            "order": "2",
                            "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                            "text": "Safety Meeting Supervisor",
                            "type": "control_fullname",
                            "answer": {
                                "first": "Nate",
                                "last": "TheFarmer"
                            },
                            "prettyFormat": "Nate TheFarmer"
                        },
                        "7": {
                            "name": "signature",
                            "order": "4",
                            "selectedField": "528c8b8186659c5616000004",
                            "text": "Signature",
                            "type": "control_signature",
                            "answer": "https://www.jotform.com/uploads/hopfenate/212717244375052/5102300875617044345/5102300875617044345_signature_7.png"
                        },
                        "8": {
                            "maxValue": "",
                            "name": "numberOf",
                            "order": "3",
                            "text": "Number of Employees at Safety Meeting",
                            "type": "control_number",
                            "answer": "5"
                        }
                    }
                }
            ],
            "duration": "25.17ms",
            "resultSet": {
                "offset": 0,
                "limit": 20,
                "count": 1
            },
            "limit-left": 933
        }
    },
    {
        "formID": "0309",
        "submissions": {
            "responseCode": 200,
            "message": "success",
            "content": [
                {
                    "id": "5102300355617077621",
                    "form_id": "212717251699059",
                    "ip": "207.148.176.165",
                    "created_at": "2021-10-16 17:47:15",
                    "status": "ACTIVE",
                    "new": "1",
                    "flag": "0",
                    "notes": "",
                    "updated_at": null,
                    "answers": {
                        "1": {
                            "name": "0309",
                            "order": "1",
                            "text": "0309 - Manufacturing",
                            "type": "control_head"
                        },
                        "2": {
                            "name": "submit2",
                            "order": "5",
                            "text": "Submit",
                            "type": "control_button"
                        },
                        "6": {
                            "name": "safetyMeeting",
                            "order": "2",
                            "sublabels": "{\"prefix\":\"Prefix\",\"first\":\"First Name\",\"middle\":\"Middle Name\",\"last\":\"Last Name\",\"suffix\":\"Suffix\"}",
                            "text": "Safety Meeting Supervisor",
                            "type": "control_fullname",
                            "answer": {
                                "first": "Nathan",
                                "last": "Hopfe"
                            },
                            "prettyFormat": "Nathan Hopfe"
                        },
                        "7": {
                            "name": "signature",
                            "order": "4",
                            "selectedField": "528c8b8186659c5616000004",
                            "text": "Signature",
                            "type": "control_signature",
                            "answer": "https://www.jotform.com/uploads/hopfenate/212717251699059/5102300355617077621/5102300355617077621_signature_7.png"
                        },
                        "8": {
                            "maxValue": "",
                            "name": "numberOf",
                            "order": "3",
                            "text": "Number of Employees at Safety Meeting",
                            "type": "control_number",
                            "answer": "10"
                        }
                    }
                }
            ],
            "duration": "24.73ms",
            "resultSet": {
                "offset": 0,
                "limit": 20,
                "count": 1
            },
            "limit-left": 932
        }
    }
]