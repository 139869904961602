import React from "react";
import DataTable from "react-data-table-component";
import './tables.css';

function ReminderTable(props) {
    let columns = [];
    if (props.data){
        columns = [ 
            {
                name: 'Company Name',
                selector: (row) => row.company,
                sortable: true,
                center: true,
                reorder: true
            },
            {
                name: 'Email',
                selector: (row) => row.email,
                sortable: true,
                center: true,
                reorder: true
            },
            {
                name: 'Industry',
                selector: (row) => row.industry,
                sortable: true,
                center: true,
                reorder: true
            },
            {
                name: 'Form to Send',
                selector: (row) => row.formID,
                sortable: true,
                center: true,
                reorder: true,
            },  
            {
                name: 'Form Status',
                selector: (row) => row.formStatus,
                sortable: true,
                center: true,
                reorder: true,
                cell: (row) => <div className={row.formStatus === "Ready to Use" ? "green-cell" : "red-cell"}>
                                    {row.formStatus}
                                </div>

            },
            {
                name: 'Response Status',
                selector: (row) => row.responseStatus,
                sortable: true,
                center: true,
                reorder: true,
                cell: (row) => <div className={row.responseStatus === "Response Received" ? "green-cell" : "red-cell"}>
                                    {row.responseStatus}
                                </div>
            }    
        ];
    }

    const rowSelectCriteria = row => row.responseStatus === "No Response";

    return (
        <div className="client-table">
            <div className="client-table-card">
                <DataTable
                    title="Reminders"
                    columns={columns}
                    data={props.data}
                    defaultSortFieldId={1}
                    pagination
                    paginationPerPage={15}
                    paginationRowsPerPageOptions={[10, 15, 25, 50, 100, 1000]}
                    noHeader
                    fixedHeader
                    fixedHeaderScrollHeight="50vh"
                    highlightOnHover
                    selectableRows
                    selectableRowSelected={rowSelectCriteria}
                    onSelectedRowsChange={props.onSelectedRowsChange}
                />
            </div>
        </div>
    );
}

export default ReminderTable;
