// const smHost = "http://localhost:4000";
const smHost = "https://0qftk9eui7.execute-api.ca-central-1.amazonaws.com/Prod";
const jfApiHost = "https://api.jotform.com";

export const getFormSubmissions = async (formId, key) => {
    const formSubs = await fetch_get(`${jfApiHost}/form/${formId}/submissions?apikey=${key}`);
    return formSubs;
};

export const getAppSettings = async (sessionId) => {
    const appSettingsDat = await fetch_post(`${smHost}/appsettings`, { "sessionId": sessionId });
    const appSettings = { "key": appSettingsDat.key, "sender": appSettingsDat.sender};
    return appSettings;
};

export const getForms = async (key) => {
    const forms = await fetch_get(`${jfApiHost}/user/forms?apiKey=${key}&limit=1000`);
    return forms;
};

export const sendEmails = async (info) => {
    const jsonDat = await fetch_post(`${smHost}/sendemails`, info);
    return jsonDat;
};

export const sendTestEmail = async (sessionId, recipient, subject, body) => {
    const info = { "sessionId": sessionId, "recipient": recipient, "subject": subject, "body": body };
    const resp = await fetch_post(`${smHost}/sendtest`, info);
    return resp;
};

const fetch_get = async (url) => {
    const resDat = await fetch(url);
    const jsonDat = await resDat.json();
    return jsonDat;
};

export const fetch_post = async (url, info) => {
    const resDat = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(info)
    });
    const jsonDat = await resDat.json();
    return jsonDat;
};