
const EMAIL = 'sm-email';
const SESSION_ID = 'sm-session';
// const HOST = 'http://127.0.0.1:4000';
const HOST = "https://0qftk9eui7.execute-api.ca-central-1.amazonaws.com/Prod";
const SECURITY = '/security';

let user = null;

/**
 * Get the user for the account
 * 
 * @returns user
 */
export async function appGetUser() {
    // @larry start here by getting the session id in the cookie
    if (user) return user;

    const sessionId = getCookie(SESSION_ID);
    if (!sessionId) return null;
    try {
        const resp = await mainFetch(SECURITY, { sessionId });
        user = resp.user;
        return user;
    } catch (err) {
        return String(err);
    }

}

export async function appRequestSecurityCode(obj) {
    if (obj.userType === 'newUser') {
        if (!obj.fname) return 'First Name required';
        if (!obj.lname) return 'Last Name required';
    }
    if (!validateEmail(obj.email)) return 'Not a valid email.';

    setCookie(EMAIL, obj.email, 365);

    try {
        const resp = await mainFetch(SECURITY, obj);
        return resp.msg;
    } catch (err) {
        return String(err);
    }
}

export async function appConfirmSecurityCode(email, securityCode) {
    try {
        const resp = await mainFetch(SECURITY, { email, linkId: securityCode });
        if (resp.status === 0) {
            setCookie(SESSION_ID, resp.sessionId, 35);
        }
        return resp;
    } catch (err) {
        console.log('*** We have a problem:', err);
        return String(err);
    }
}


export function appGetEmail() {
    return getCookie(EMAIL);
}

// todo@ larry and nathan - revoke session cookies on logoff
// export async function deleteCookies(obj, email, securityCode) {
//     try {
//         const resp = await mainFetch(SECURITY, { email, linkId: securityCode });
//         if (resp.status === 0) {
//             setCookie(SESSION_ID, resp.sessionId, 0);
//         }
//         setCookie(EMAIL, obj.email, 0);
//     } catch (err) {
//         console.log('*** We have a problem:', err);
//         return String(err);
//     }
// }

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// const mainFetch = async (route, info) => {

async function mainFetch(route, info) {
    const resDat = await fetch(HOST + route, {
        // credentials: 'include',
        method: 'POST',
        // headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(info)
    });
    const jsonDat = await resDat.json();
    return jsonDat;
}
