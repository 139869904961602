import React from 'react';
import LCMTable from './tables/LCMTable';
import { sendEmails } from "../data/fetch";
import functions from "../utils/functions";
import MonthDropdown from "./MonthDropdown";
import '../App.css';

class LCM extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRecipients: [],
        };
    }
    
    onSend = async () => {
        const { setMsg, user } = this.props;
        let response = functions.getEmailList(this.state.selectedRecipients);
        const info = { "sessionId": user.sessionId, "emailType": "monthly", "emailList": response.emailList };
        setMsg(response.msg, response.msgType);
        if (response.emailList) {
            response = await sendEmails(info);
            setMsg(response.msg, response.msgType);
            if (response.msgType === 'message') {
                this.setState({ selectedRecipients: [] });
            }
        }
    };

    onSelectedRowsChange = (selected) => {
        this.setState({ selectedRecipients: selected.selectedRows });
    };

    render() {
        return (
            <div className="lcm-page">
                <div className="lcm-header">
                    <div className="lcm-header-left">
                        <h2>Loss Control Meetings</h2>
                    </div>
                    <div className="lcm-header-right">
                        <h3 className="lcm-admin-email">Sender Email: {this.props.senderEmail}</h3>
                        <MonthDropdown projectDate={this.props.projectDate} handleChange={this.props.handleChange} />
                        <div className="lcm-btns-container">
                            <h4 className="loss-control-btn" onClick={() => this.props.onSendTest("monthly")}>Send Test</h4>
                            <h4 className="loss-control-btn" onClick={this.onSend}>Send Emails</h4>
                        </div>
                    </div>
                </div>
                <LCMTable data={this.props.data} onSelectedRowsChange={this.onSelectedRowsChange} />
            </div>
        )
    }
}

export default LCM;