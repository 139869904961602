import './Version.css';
import version from './version.json';

function Version() {
    return (
        <div className="version">
            <a href="https://leanminds.ca" target="_blank" title="leanminds.ca" rel="noreferrer">
                A passion for developing<br />
                Env: {version.gitBranch} <br />
                Build: {version.buildTime}
            </a>
        </div>

    );
}

export default Version;